.ContactForm_container {
  width: 100%;
  float: left;
  top: 0px;
  background-color: rgb(255, 255, 255);
  padding: 8px;
}

.ContactForm {
  width: 100%;
  margin-top: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  float: left;
}
.grup-center{width: 100%;}
form h3 {
  margin-bottom: 20px;
  text-align: right;
  font-size: 15px;
}

.grup {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.grup-row {
  width: 49% !important;
  display: block;
}

.ContactForm-input-text,
.SElect,
textarea {
  width: 100% !important;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  float: right;
  text-align: right;
}

.SElect{
  text-align: center;
}
.react-tel-input .form-control {
  height: 45px;
}

.ContactForm-input-text {
  padding: 15px !important;
}

.address-preview {
  float: right;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}

.Shipping-method {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: inline-block;
}

.address-sub-title {
  color: hsl(0, 0%, 20%);
  font-size: 15px;
  padding: 15px;
  text-align: right;
  font-weight: 700;
}

.Delivery-card {
  float: right;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  margin-left: 0%;
  padding: 20px;
}

.Delivery-card:hover {
  background-color: #a68ec215;
  border: 2px solid #c66ee1;
}

.Delivery-checked {
  float: right;
  width: 10px;
  margin-right: 17px;
  margin-top: 12px;
}

.Delivery-option-icon {
  float: right;
  margin-right: 0px;
  width: 35px;
  margin-top: 4px;
}

.DElivery-card-content {
  padding: 0;
  float: right;
  margin-right: 2%;
  width: 45% !important;
  text-align: right;
}

.card-title {
  float: right;
  padding: 0;
  width: 100%;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.card-text {
  margin-top: 5px;
  float: right;
  width: 100%;
  font-size: 14px;
  color: #666;
  display: block;
}

.DElivery-card-price {
  padding: 0;
  float: right;
  width: 25%;
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: block;
}

.desk-card {
  width: 100%;
  display: block;
}

.mobile-card {
  width: 100%;
  display: none;
}

.ContactForm-label {
  display: flex;
  direction: rtl;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
  text-align: right;
  font-size: 15px;
}

.required-star {
  color: red;
  width: 30px;
  padding: 8px;
  display: block !important;
  float: right;

}
.required-text{
  display: block !important;
  float: right;
 
  }
   

.rtl .ContactForm-label {
  text-align: right;
  direction: rtl;
  display: block;
}

.ltr .ContactForm-label {
  text-align: left;
  direction: ltr;
  display: block;
}

@media screen and (max-width: 780px) {
  .ContactForm_container {
    padding: 15px;
  }

  .ContactForm {
    width: 98%;
    margin-left: 1%;
    margin-top: 0px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f7f7f8;
    float: left;
    border: 1px solid #ccc;
  }
  .grup-center{width: 90%;margin-left: 5%;}
  .grup {
    width:100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-left:0;
   
  }

  .grup-row {
    width: 100% !important;
    background-color: transparent;
    height: auto;
    margin-bottom: 15px; 

  }
  
  .ContactForm-input-text {
    padding: 15px !important;
    display: block !important;
    background-color: white !important;
  }

  .SElect {
    width: 100% !important;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    float: right;
    text-align: right;
  }

  .react-tel-input .form-control {
    height: 50px;
    display: block !important;
    background-color: white !important;
    width: 100% !important; 
    margin-bottom: 10px; 
  }

  .desk-card {
    width: 100%;
    display: none;
  }

  .mobile-card {
    width: 100%;
    display: block;
  }

  .ContactForm-label {
    font-size: 14px;
    width: 100%;
    display: block !important;  
    text-align: right;  
    margin-bottom: 10px;  
  }

  .ContactForm-label-span {
    font-size: 16px;
    color: #000 !important;
    display: block;  
    margin-bottom: 5px;  
  }

  .required-star {
    color: red;
    width: auto; 
    padding: 0;  
    margin-left: 5px;  
    display: block !important;
    
  }
}
